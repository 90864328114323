<template>
  <v-container>
    <v-layout class="temp" row wrap>
      <div :class="`text-h5 pa-4`" v-if="Active.length !== 0">
        Previous Runs:
      </div>
      <table-view
        :dialog="ResultsDialog"
        :results_data="run_id_results"
        v-on:DownloadFile="DownloadFile"
        v-on:eventname="UpdateTableView"
        :run_id="current_run_id"
      />

      <v-flex md12 xs12>
        <!--<v-layout align-space-around justify-space-around  row fill-height fill-width wrap>-->
        <!--<v-flex md12 xs4>-->
        <!--<v-btn color="success">Normal</v-btn>-->
        <!--</v-flex>-->
        <!--<v-flex md12 xs4>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--</v-flex>-->
        <!--<v-flex md12 xs4>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--</v-flex>-->

        <!--<v-btn color="success">Success</v-btn>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--<v-btn color="success">Success</v-btn>-->
        <!--<v-layout align-space-around justify-space-around  row fill-height fill-width>-->
        <!--<v-btn color="success">Previous</v-btn>-->
        <!--<v-btn color="success">Mark</v-btn>-->
        <!--<v-btn color="success">Next</v-btn>-->
        <!--</v-layout>-->

        <!--</v-layout>-->

        <v-item-group>
          <v-container grid-list-md>
            <v-layout wrap>
              <!--{{$route.params.id}}-->

              <v-flex v-for="n in Active" :key="n" xs12 sm12 md6 lg4 >
                <v-item>
                  <v-hover open-delay="200">
                    <v-card
                      class="d-flex align-center card-custom"
                      color="primary"
                      dark
                      @click="display_results(n)"
                    >
                      <!--                            <div width="100%" height="100%" style="text-align:center">-->
                      <!--                              {{ n }}-->
                      <!--                            </div>-->
                      <v-card-title>
                        <v-list-item class="grow">
                          <v-list-item-avatar color="white">
                            <v-img
                              contain
                              v-bind="size"
                              class="elevation-6"
                              alt=""
                              src="../assets/sperm.png"
                            ></v-img>
                          </v-list-item-avatar>

                          <!--                    <span class="headline font-weight-bold">{{ n }}</span>-->

                          <v-card-text class=" justify-center font-weight-bold">
                            {{ n }}
                          </v-card-text>
                        </v-list-item>
                      </v-card-title>

                      <!--                            <v-card-text-->
                      <!--                              class=" justify-center font-weight-bold"-->
                      <!--                            >-->
                      <!--                              {{ n }}-->
                      <!--                            </v-card-text>-->
                    </v-card>
                  </v-hover>
                </v-item>
              </v-flex>
            </v-layout>
          </v-container>
        </v-item-group>
      </v-flex>

      <!--      <v-main>-->
      <!--        <v-container>-->
      <!--          <v-row>-->
      <!--            <v-col v-for="n in Active" :key="n" cols="4" @click="give_label(n)">-->
      <!--              <v-card class="mx-auto card-custom" color="#26c6da" dark>-->
      <!--                <v-card-title>-->
      <!--                  <v-list-item class="grow">-->
      <!--&lt;!&ndash;                    <v-list-item-avatar color="white">&ndash;&gt;-->
      <!--&lt;!&ndash;                      <v-img&ndash;&gt;-->
      <!--&lt;!&ndash;                        class="elevation-6"&ndash;&gt;-->
      <!--&lt;!&ndash;                        alt=""&ndash;&gt;-->
      <!--&lt;!&ndash;                        src="../assets/sperm.png"&ndash;&gt;-->
      <!--&lt;!&ndash;                      ></v-img>&ndash;&gt;-->
      <!--&lt;!&ndash;                    </v-list-item-avatar>&ndash;&gt;-->

      <!--                    &lt;!&ndash;                    <span class="headline font-weight-bold">{{ n }}</span>&ndash;&gt;-->

      <!--                    <v-card-text-->
      <!--                      class=" justify-center font-weight-bold"-->
      <!--                    >-->
      <!--                      {{ n }}-->
      <!--                    </v-card-text>-->
      <!--                  </v-list-item>-->
      <!--                </v-card-title>-->
      <!--              </v-card>-->
      <!--            </v-col>-->
      <!--          </v-row>-->
      <!--        </v-container>-->
      <!--      </v-main>-->
    </v-layout>
    <v-divider thickness="10"></v-divider>

    <v-layout align-center justify-center class="ma-16">
      <v-flex xs12 sm12 md12>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Upload Folder with slides</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <!-- <template v-slot:activator="{ on }">
                <v-btn :href="source" icon large target="_blank" v-on="on">
                  <v-icon large>code</v-icon>
                </v-btn>
              </template> -->
              <span>Source</span>
            </v-tooltip>
            <!-- <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon large href="https://codepen.io/johnjleider/pen/wyYVVj" target="_blank" v-on="on">
                  <v-icon large>mdi-codepen</v-icon>
                </v-btn>
              </template>
              <span>Codepen</span>
            </v-tooltip> -->
          </v-toolbar>
          <v-card-text>
            <upload
              ref="upload"
              name="files"
              :async-save-url="'custom-save-url'"
              :async-remove-url="'custom-remove-url'"
              :async-chunk-size="11000"
              :directory="true"
              :directory-drop="true"
            >
            </upload>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--            <v-btn @click="postPost" color="primary">Login</v-btn>-->

            <v-btn @click="run_sperm_morph" color="primary"
              >Run Sperm Morphology</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <!--      <v-flex md12 xs12>-->
    <!--      <v-btn @click="uploadDialog = true"-->
    <!--             block-->
    <!--             color="primary"-->
    <!--             elevation="9"-->
    <!--             large-->
    <!--             x-large>-->
    <!--        <v-icon size="30">mdi-plus</v-icon>-->
    <!--        Upload and Get Predictions-->
    <!--      </v-btn>-->

    <!--      <upload-->
    <!--          :dialog.sync="uploadDialog"-->
    <!--          :multiple="true"-->
    <!--          @filesUploaded="processUpload($event)"-->
    <!--      />-->
    <!--      </v-flex>-->
  </v-container>
</template>

<script>
// import * as Buffer from "vuetify";
import "@progress/kendo-theme-default/dist/all.css";

// import Upload from "@/views/Upload";
import TableView from "@/views/TableView";
const axios = require("axios");
import store from "../store";
import { Upload } from "@progress/kendo-upload-vue-wrapper";

export default {
  components: {
    TableView,
    upload: Upload
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    cors: "https://cors-anywhere.herokuapp.com/",
    sperm_image: null,
    // username: "",
    userid: "",
    Active: [],
    run_id_results: {},
    label: "",
    imageno: "",
    // slide: "",
    image_name: "",
    current_run_id: "",
    dragover: false,
    uploadedFiles: [],
    ResultsDialog: false,
    backendhostname: "",
    shouldReload: false
  }),
  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    get_user() {
      return store.state.userid;
    }
  },
  watch: {},

  created() {
    // this.$parent.subtitle = "Slides";
    // console.log("Created");
    this.give_slides();
    // this.userid = store.state.userid;

    // console.log("userid: ", this.userid);
    // if (this.userid == null) {
    //   this.$router.push({ path: "/login" });
    // }
    this.backendhostname = this.$backendhostname;
  },
  methods: {
    get_backend_url() {
      return this.$backendhostname;
    },
    run_sperm_morph() {
      let data = {
        run_id: this.current_run_id
      };
      console.log("Run ID: before sm", this.current_run_id);
      const get_sli = this.get_backend_url() + "/run_sperm_morph";
      this.$isLoading(true); // show loading screen

      axios
        // .post(this.cors + get_sli, {
        .post(get_sli, data, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "X-Requested-With": "XMLHttpRequest"
          }
        })

        .then(response => {
          // console.log(response);
          console.log(response.data);
          this.run_id_results = response.data.run_id_results;
          // var res = response.data.user

          // dispatch({type: FOUND_USER, data: response.data[0]})
          this.shouldReload = true;
          this.display_results(this.current_run_id);
        })
        .catch(error => {
          this.resp = error;
          console.error(error);

          // dispatch({type: ERROR_FINDING_USER})
        })
        .finally(() => {
          this.$isLoading(false); // hide loading screen
        });
    },
    UpdateTableView(val) {
      this.ResultsDialog = val;
      if (this.shouldReload) this.$router.go();

      var run_id = this.getNow();
      this.current_run_id = "Run-" + (this.Active.length + 1) + "___" + run_id;
    },
    DownloadFile(val) {
      console.log(val);
      let data = {
        run_id: this.current_run_id
      };
      const get_sli = this.$backendhostname + "/download_run_results";
      axios
        // .post(this.cors + get_sli, {
        .post(get_sli, data, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "X-Requested-With": "XMLHttpRequest"
          }
        })

        .then(response => {
          // console.log(response);
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.current_run_id + ".csv");
          document.body.appendChild(fileLink);

          fileLink.click();
          // var res = response.data.user

          // dispatch({type: FOUND_USER, data: response.data[0]})
        })
        .catch(error => {
          this.resp = error;
          console.error(error);

          // dispatch({type: ERROR_FINDING_USER})
        });
    },

    get_run_results: function(run_id) {
      let data = {
        run_id: run_id
      };
      const get_sli = this.get_backend_url() + "/get_run_results";
      axios
        // .post(this.cors + get_sli, {
        .post(get_sli, data, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "X-Requested-With": "XMLHttpRequest"
          }
        })

        .then(response => {
          // console.log(response);
          console.log(response.data);
          this.run_id_results = response.data.run_id_results;
          // var res = response.data.user

          // dispatch({type: FOUND_USER, data: response.data[0]})
        })
        .catch(error => {
          this.resp = error;
          console.error(error);

          // dispatch({type: ERROR_FINDING_USER})
        });
    },
    // split_run_id: function(run_id) {
    //   console.log(run_id,parseInt(run_id.split("___")[0].replace("run-", "")))
    //   return parseInt(run_id.split("___")[0].replace("run-", ""));
    // },
    sortArrays(arrays) {
      let _;
      return _.orderBy(arrays, "name", "asc");
    },
    give_slides: function() {
      var run_id = this.getNow();
      this.current_run_id =
          "Run-" + (this.Active.length + 1) + "___" + run_id;
      console.log("mounted run id", this.current_run_id);

      const get_sli = this.$backendhostname + "/get_all_run_ids";
      axios
        // .post(this.cors + get_sli, {
        .post(get_sli, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "X-Requested-With": "XMLHttpRequest"
          }
        })

        .then(response => {
          // console.log(response);
          if (response.data.run_ids !== "") {
            this.Active = response.data.run_ids.split(";");
            // var res = response.data.user
            console.log(response.data.run_ids.split(";"));

            this.Active = response.data.run_ids.split(";");
            // this.Active = this.Active.sort(
            //   (a, b) => this.split_run_id(a.name) - this.split_run_id(b.name)
            // );
            var run_id = this.getNow();
            this.current_run_id =
              "Run-" + (this.Active.length + 1) + "___" + run_id;
            console.log("mounted run id", this.current_run_id);
            // dispatch({type: FOUND_USER, data: response.data[0]})
          }
        })
        .catch(error => {
          this.resp = error;
          console.error(error);

          // dispatch({type: ERROR_FINDING_USER})
        });
    },
    display_results: function(run_id) {
      this.current_run_id = run_id;
      store.commit("store_current_slide", this.current_run_id);

      this.ResultsDialog = true;
      this.get_run_results(run_id);
      if (this.shouldReload) this.Active.push(this.current_run_id);

      // this.$router.push({
      //   // path: `/slide/${this.current_slide}/${this.username}`
      //
      //   path: "/label"
      // });
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + "_" + time;
      // this.thisimestamp = dateTime;
      return dateTime;
    }
  },
  mounted: function() {
    /*
        The code in this function is only added to simulate a successful upload request for this demo.
        Do not use the code in other cases when working with the Upload component.
    */

    var upload = this.$refs.upload.kendoWidget();
    const get_sli = this.backendhostname + "/single_file_upload";
    var vm = this;
    // eslint-disable-next-line no-unused-vars
    upload._module.postFormData = function(url, data, fileEntry, xhr) {
      var module = this;
      // var counter = 0;
      var uid = fileEntry.data("files")[0].uid;

      // console.log(data);
      // console.log(fileEntry.data("files"));
      console.log(fileEntry.data("files")[0]);

      let formData = new FormData();
      formData.append(
        fileEntry.data("files")[0].name.replace(" ", "_"),
        fileEntry.data("files")[0].rawFile
      );
      formData.append("run_id", vm.current_run_id);
      console.log("Upload run_id", vm.current_run_id);
      axios
        .post(get_sli, formData, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept"
          }
        })
        .then(function() {
          // console.log("SUCCESS!!");
          var e = {
            target: {
              responseText: '{"uploaded":true,"fileUid":"' + uid + '"}',
              statusText: "OK",
              status: 200
            }
          };
          module.onRequestSuccess.call(module, e, fileEntry);
        })
        .catch(function() {
          console.log("FAILURE!!");
        });

      // var refreshIntervalId = setInterval(function() {
      //   if (counter > 100) {
      //     clearInterval(refreshIntervalId);
      //     var e = {
      //       target: {
      //         responseText: '{"uploaded":true,"fileUid":"' + uid + '"}',
      //         statusText: "OK",
      //         status: 200
      //       }
      //     };
      //     module.onRequestSuccess.call(module, e, fileEntry);
      //     return;
      //   }
      //   module.upload._onFileProgress(
      //     {
      //       // eslint-disable-next-line no-undef
      //       target: $(fileEntry, module.upload.wrapper)
      //     },
      //     counter
      //   );
      //   counter += 10;
      // }, 100);
    };

    upload._submitRemove = function(fileNames, eventArgs, onSuccess, onError) {
      console.log(onError);
      onSuccess();
    };
    upload._module.stopUploadRequest = function(fileEntry) {
      console.log(fileEntry);
    };
  }
};
</script>

<style>
.card-custom {
  align-content: center;

  /*height: 100px;*/
}
/*@media (max-width: 425px) {*/
/*  .temp {*/
/*    flex-direction: row;*/
/*  }*/
/*  .card-custom {*/
/*    !*height: 100px;*!*/
/*    align-content: center;*/
/*    !*width: 200px;*!*/
/*  }*/

/*  .image {*/
/*    !* object-fit: cover; *!*/
/*    !* max-height: 60vh; *!*/
/*    !* height: 30px; *!*/
/*    !* width: 30px; *!*/
/*    !* width: 100v÷w; *!*/
/*  }*/
/*}*/
.image {
  /* object-fit: cover; */
  /* max-height: 60vh; */
  /* height: 80vh; */
  /* width: 80vh; */
  /* width: 100v÷w; */
}
</style>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>
