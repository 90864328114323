<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="dialog"
    max-width="1000"
    persistent
  >
    <v-card class="lighten-2 elevation-15">
      <div style="margin: 0 auto; width: 800px">
        <!--        <code>query: {{ query }}</code>-->
        <div :class="`text-h5`">Results of run : {{ this.run_id }}</div>
        <datatable v-bind="$data" />
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-card-actions>
          <v-btn color="primary" text @click="DownloadFile">
            Download
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import mockData from "../_mockData";

export default {
  name: "TableView",

  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    results_data: {
      default: {}
    },
    run_id: {
      default: {}
    }
  },

  data() {
    return {
      dragover: false,
      fixHeaderAndSetBodyMaxHeight: 200,
      tblStyle: "table-layout: fixed", // must
      tblClass: "table-bordered",
      // columns: [
      //   { " ": " " },
      //   {" Standard deviation -  Good Sperm Percentage": " Standard deviation -  Good Sperm Percentage", sortable: true, fixed: true },
      //   { "Bad Sperm Percentage": "Bad Sperm Percentage" , sortable: true, fixed: true },
      //   { "Bad sperm count": "Bad sperm count", sortable: true, fixed: true },
      //   { "Good Sperm Percentage": "Good Sperm Percentage" , sortable: true, fixed: true },
      //   { "Good sperm count": "Good sperm count", sortable: true, fixed: true },
      //   { "Mean - Good Sperm Percentage": "Mean - Good Sperm Percentage" , sortable: true, fixed: true },
      //   { "Slide_name": "Slide_name", sortable: true, fixed: true },
      //   { "Total Count": "Total Count", sortable: true, fixed: true },
      // ].map(col => ((col.colStyle = { width: "200px" }), col)),

      // "Slide_Name", "Good_%", "Bad_%", "Good_Count",
      // "Bad_Count", "Total_Count","SD_Good_%",
      // "Mean_Good_%"
      columns: [
        { title: "Slide", field: "Slide_Name", fixed: true },
        { title: "Good (%)", field: "Good_%" },
        { title: "Bad (%)", field: "Bad_%", sortable: true },
        { title: "Mean Good %", field: "Mean_Good_%", sortable: true },
        { title: "SD Good %", field: "SD_Good_%", sortable: true },
        { title: "Good Count", field: "Good_Count", sortable: true },
        { title: "Bad Count", field: "Bad_Count", sortable: true },
        { title: "Total Count", field: "Total_Count", sortable: true }
        // { title: "Country", field: "country", fixed: "right" }
      ].map(col => ((col.colStyle = { width: "150px" }), col)),

      data: this.results_data,
      // summary: {},
      total: this.results_data.length,
      query: {}
    };
  },

  methods: {
    closeDialog() {
      this.$emit("eventname", false);
      // this.dialog = false
    },
    attach_data() {
      // for for
      this.data = this.results_data;
      this.total = this.results_data.length;
    },
    DownloadFile() {
      this.$emit("DownloadFile", true);
    }
  },
  watch: {
    query: {
      handler(query) {
        // mockData(query).then(({ rows, total, summary }) => {
        //   this.data = rows;
        //   this.total = total;
        //   this.summary = summary;
        // });
        console.log(query);
        this.data = this.results_data;
        this.total = this.results_data.length;
      },
      deep: true
    },
    results_data: {
      handler() {
        this.data = this.results_data;
        this.total = this.results_data.length;
      }
    }
  },
  created() {
    this.attach_data();
  }
};
</script>
